import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
// import firebase from "firebase/app";


import './styles/normalize.css';
// import './styles/all.min.css';
import './styles/index.scss';

import RootApp from './routers/RootApp';
import reportWebVitals from './reportWebVitals';

import { hotjar } from 'react-hotjar';

// import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/analytics';
// import { FirebaseAppProvider } from 'reactfire';
// import ReactGA from 'react-ga';
// ReactGA.initialize('G-YVZT7NDTGS');
// ReactGA.pageview(window.location.pathname + window.location.search);
const firebaseConfig = {
    apiKey: "AIzaSyAR9JqeRkFS4Vb4p-mjzwQOnr5rwlhwCFk",
    authDomain: "vietndbase.firebaseapp.com",
    databaseURL: "https://vietndbase-default-rtdb.firebaseio.com",
    projectId: "vietndbase",
    storageBucket: "vietndbase.appspot.com",
    messagingSenderId: "1045967086558",
    appId: "1:1045967086558:web:d7dd6b282fb2ccfc39f34f",
    measurementId: "G-0S4HB077JN"
};
firebase.initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
firebase.analytics()
// eslint-disable-next-line no-unused-vars
firebase.performance();

hotjar.initialize(2204991, 6)

ReactDOM.render(
    <React.StrictMode>
        <Router>
            {/* <FirebaseAppProvider firebaseConfig={firebaseConfig} analytics={analytics}> */}
                <RootApp />
            {/* </FirebaseAppProvider> */}
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// Initialize Firebase
// firebase.initializeApp(firebaseConfig)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
