import React, { useEffect, useState, Suspense, lazy } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { GlobalEventProvider, glContext } from "../context/Context";

import Loading from "../components/Loading";
const Home = lazy(() => import("../page/home/Home"));
// import { AsideBar, NavBar } from "../components/NavBar";
const PageNotFound = lazy(() => import("../page/PageNotFound"));

const Portfolio = lazy(() => import("../page/portfolio/Portfolio"));

// import { FirebaseAppProvider } from 'reactfire';
library.add(far, fab, fas);

// const routes = [
// 	{
// 		path: "/",
// 		exact: true,
// 		component: Home
// 	},
// 	{
// 		path: "/portfolio/",
// 		exact: true,
// 		component: Portfolio,

// 		route: [
// 			{
// 				path: "/portfolio/:tabId",
// 				component: Portfolio
// 			}
// 		]
// 	},
// 	{
// 		path: "/404-Page-Not-Found",
// 		component: PageNotFound
// 	}
// ];

const RootApp = () => {
	// global context
	const [globalStorage, setGlobalStorage] = useState(glContext);

	// show or hide navbar
	const location = useLocation();

	useEffect(() => {
		// console.log(document.head)
	}, []);

	useEffect(() => {
		// console.log("root run");
		const locate = location.pathname;
		if (locate === "/") {
			setGlobalStorage((prevState) => ({
				...prevState,
				hasShowTopNav: false,
				inHome: true,
			}));
		} else {
			setGlobalStorage((prevState) => ({
				...prevState,
				hasShowTopNav: true,
				inHome: false,
			}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	return (
		// <FirebaseAppProvider firebaseConfig={firebaseConfig}>
		<GlobalEventProvider
			value={{ globalStorage, setGlobalStorage: setGlobalStorage }}
		>
			{/* <NavBar className={globalStorage.hasShowTopNav ? "" : "hide"} />
				<AsideBar
					inHome={globalStorage.inHome}
					className={globalStorage.hasShowTopNav ? "" : "hide"}
				/> */}
			<Suspense fallback={<Loading />}>
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route exact path="/portfolio">
						<Portfolio />
					</Route>
					<Route path="/portfolio/:tabId">
						<Portfolio />
					</Route>
					{/* <Route exact path="/load"><Loading/></Route> */}
					<Route exact path="/404-Page-Not-Found">
						<PageNotFound />
					</Route>
					{/* {routes.map((route, i) => (
						<RouteWithSubRoutes key={i} {...route} />
					))} */}
					<Redirect from="*" to="/404-Page-Not-Found" />
				</Switch>
			</Suspense>
		</GlobalEventProvider>
		// </FirebaseAppProvider>
	);
};
// function RouteWithSubRoutes(route) {
// 	// console.log(route)
// 	return (
// 		<Route
// 			path={route.path}

// 			render={props => (
// 				// pass the sub-routes down to keep nesting
// 				<route.component {...props} routes={route.routes} />
// 			)}

// 		/>
// 	);
// }

export default RootApp;
