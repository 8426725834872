import { createContext } from "react";

import 'firebase/firestore';
// import { useFirestoreDocData, useFirestore } from 'reactfire';

export const GlobalEvent = createContext();

export const GlobalEventProvider = GlobalEvent.Provider;

// export const SetHead = (name, data = {}) => {
//     const ele = document.createElement(name)
//     ele.setAttribute
// }



// export function getPages() {
// 	// easily access the Firestore library
// 	const pageRef = useFirestore()
// 		.collection("tryreactfire")
// 		.doc("pages");

// 	// subscribe to a document for realtime updates. just one line!
// 	const { status, data } = useFirestoreDocData(pageRef);

// 	// easily check the loading status
// 	if (status === "loading") {
// 		return {};
// 	}
//     console.log(data)
// 	return {};
// }

export const glContext = {
	hasShowAsideNav: false,
	hasShowTopNav: false,
	inHome: true,
	asideListItems: [
		{
			title: "Information",
			link: "/portfolio/information",
			icon: "address-card",
			hasActive: false,
			hasChild: false,
			showChild: false,
			child: [],
		},
		// {
		// 	title: "Web design",
		// 	link: "/portfolio/app",
		// 	icon: "terminal",
		// 	hasActive: false,
		// 	hasChild: true,
		// 	child: [
		// 		{
		// 			title: "Website",
		// 			link: "/portfolio/app/website",
		// 			hasActive: false
		// 		},
		// 		{
		// 			title: "Platform",
		// 			link: "/portfolio/app/Platform",
		// 			hasActive: false
		// 		},
		// 		{
		// 			title: "Landingpage",
		// 			link: "/portfolio/app/landingpage",
		// 			hasActive: false
		// 		},
		// 	],
		// },
		// {
		// 	title: "Graphic design",
		// 	link: "/portfolio/des",
		// 	icon: "vector-square",
		// 	hasActive: false,
		// 	hasChild: false,
		// 	child: [],
		// },
		// {
		// 	title: "Digital art",
		// 	link: "/portfolio/art",
		// 	icon: "paint-brush",
		// 	hasActive: false,
		// 	hasChild: false,
		// 	child: [],
		// },
		// {
		// 	title: "Download CV",
		// 	link: "/portfolio/cv",
		// 	icon: "file-download",
		// 	hasActive: false,
		// 	hasChild: false,
		// 	child: [],
		// },
	],
};
