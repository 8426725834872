import React from "react";
import "../styles/loading.scoped.scss"

export const Loading = () => {
	return (
		<div className="full-screen">
			<div className="boxes">
				<div className="box">
					<div />
					<div />
					<div />
					<div />
				</div>
				<div className="box">
					<div />
					<div />
					<div />
					<div />
				</div>
				<div className="box">
					<div />
					<div />
					<div />
					<div />
				</div>
				<div className="box">
					<div />
					<div />
					<div />
					<div />
				</div>
			</div>
		</div>
	);
};
export default Loading